import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckboxModule } from 'primeng/checkbox';
import { BlockUIModule } from 'primeng/blockui';
import { SpinnerModule } from 'primeng/spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputSwitchModule } from 'primeng/inputswitch';
//import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
//import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from "primeng/tooltip";
import {LightboxModule} from 'primeng/lightbox';
import {CarouselModule} from 'primeng/carousel';
import {EditorModule} from 'primeng/editor';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FileUploadModule} from 'primeng/fileupload';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SplitButtonModule} from 'primeng/splitbutton';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeModule } from "primeng/tree";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CheckboxModule,
    BlockUIModule,
    SpinnerModule,
    ProgressSpinnerModule,
    ToastModule,
    PasswordModule,
    DialogModule,
    ScrollPanelModule,
    InputSwitchModule,
    //SidebarModule,
    MenubarModule,
    ToggleButtonModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    //SlideMenuModule,
    TableModule,
    ButtonModule,
    PanelMenuModule,
    CardModule,
    TabViewModule,
    KeyFilterModule,
    RadioButtonModule,
    CarouselModule,
    EditorModule,
    SelectButtonModule,
    FileUploadModule,
    OverlayPanelModule,
    SplitButtonModule,
    AccordionModule,
    InputNumberModule

  ],
  exports : [
    CheckboxModule,
    BlockUIModule,
    SpinnerModule,
    ProgressSpinnerModule,
    ToastModule,
    PasswordModule,
    DialogModule,
    ScrollPanelModule,
    InputSwitchModule,
   // SidebarModule,
    MenubarModule,
    ToggleButtonModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    //SlideMenuModule,
    TableModule,
    ButtonModule,
    PanelMenuModule,
    CardModule,
    TabViewModule,
    KeyFilterModule,
    RadioButtonModule,
    FieldsetModule,
    TooltipModule,
    LightboxModule,
    CarouselModule,
    EditorModule,
    SelectButtonModule,
    FileUploadModule,
    OverlayPanelModule,
    SplitButtonModule,
    AccordionModule,
    InputNumberModule,
    TreeModule,
  
  ]
})
export class PrimengModule { }
