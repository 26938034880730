import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/token';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { VwUsuarioRazon } from '../models/vwUsuarioRazon';
import * as CryptoJS from 'crypto-js';
import { LocalStoreService } from './local-store.service';
import { RecoverPassword } from '../models/recoverpassword';
import { ArchivoRespDocumento } from '../models/archivoRespDocumento';
import { Observable } from 'rxjs';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private resource1: string = environment.api_url_java;
  header_ini: HttpHeaders;
  objUsuario: VwUsuarioRazon = new VwUsuarioRazon();
  private usuario: VwUsuarioRazon;
  private resource: string = environment.api_url_java + 'usuario/';
  url_logout: string = `${environment.HOST}/api/tokens`;
  password: string;
  conversionOutput: string;
  constructor(private http: HttpClient, private router: Router,private store: LocalStoreService,) {
    
   }

  loginToken(prmData: User) {
    return this.http.post(this.resource1 + 'token/user/security', prmData).toPromise();
  }

  isLogged() {
    const token = localStorage.getItem("usuario");
    return (token) ? true : false;
  }

  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');

    let access_token = JSON.parse(this.store.getItem(environment.TOKEN_NAME)).access_token;
    this.http.get(`${this.url_logout}/anular/${access_token}`).subscribe(() => {
      this.store.clear();
    });
  }

  getAllusuariorazon(codigo:string) {
    return this.http.get<VwUsuarioRazon>(this.resource + 'usuariorazon/'+codigo);
  }

  getAllusuariorazonActive(codigo:string, versionWeb:string) {
    return this.http.get<Usuario>(`${this.resource}usuariorazonactivo/${codigo}/${versionWeb}`);
  }


  public getUser(): VwUsuarioRazon {


    this.password ="15646^&amp;%$3(),>2134bgGz*-+e7hds";
    this.conversionOutput = CryptoJS.AES.decrypt(localStorage.getItem('usuario'), this.password.trim()).toString(CryptoJS.enc.Utf8);
    let user = JSON.parse(this.conversionOutput);

    this.objUsuario = user;

    this.usuario = new VwUsuarioRazon();
    this.usuario.cod_usuario = this.objUsuario.cod_usuario
    this.usuario.id_rzn_scl = this.objUsuario.id_rzn_scl
    this.usuario.id_usuario = this.objUsuario.id_usuario
    this.usuario.nom_rzn_social_prov = this.objUsuario.nom_rzn_social_prov
    this.usuario.num_ruc_prov = this.objUsuario.num_ruc_prov
    this.usuario.id_perfil = this.objUsuario.id_perfil
    this.usuario.txt_pass_usuario = this.objUsuario.txt_pass_usuario
    
    return this.usuario;
  }

  


  
  CorreoCodigoVerificacion(prmData:RecoverPassword) : Observable<ArchivoRespDocumento> {
    console.log("entro")
    return this.http.post<ArchivoRespDocumento>(environment.ENVIOCORREO, prmData);

  }

  generarCodigo(prmData : Usuario){
    return this.http.post<Usuario>(environment.HOST+'/pass/passwordsecurity/recover', prmData);

  }

  validarCodigo(prmData : RecoverPassword){

    return this.http.post<RecoverPassword>(environment.HOST+'/pass/passwordsecurity/validarcodigo', prmData);
  }

  updatePassword(prmData : Usuario){

    return this.http.post<Usuario>(environment.HOST+'/pass/passwordsecurity/updatepassword', prmData);

  }

  
}
